import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import rootReducer  from './reducers'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { compose } from 'redux'

//IE対応
import es6Promise from 'es6-promise'
es6Promise.polyfill();

const store = createStore(rootReducer, compose(
    process.env.NODE_ENV === 'development' && window.devToolsExtension ? window.devToolsExtension(): f => f
))


const theme = createMuiTheme({
  //デフォルトのカラーセット
  palette: {
    type: 'light',
    primary: {
      //indigo
      main: '#3f51b5',
      
    },
    secondary: {
      //deeppurple
      main:"#ec6c00",
    }
  },
  //新しいバージョンでは記述必須となった
  typography: {
    useNextVariants: true,
  },
  'breakpoints': {
    'keys': [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
    ],
    'values': {
      'xs': 360, // スマホ用
      'sm': 768, // タブレット用
      'md': 992, // PC用
      'lg': 1000000000, 
      'xl': 1000000000,
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>
  , document.getElementById('root')
);

serviceWorker.register();
