import React, { Component } from 'react';
import SearchForm from './components/SearchForm'
import Map from './components/Map'

import { bindActionCreators } from 'redux';

import TermsOfServiceDialog from './components/TermsOfServiceDialog'
import HowToUseDialog from './components/HowToUseDialog'
import SettingsDialog from './components/SettingsDialog'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';

import MailIcon from '@material-ui/icons/Mail'
import HelpIcon from '@material-ui/icons/Help'
import SettingsIcon from '@material-ui/icons/Settings'

import * as actions from './actions';

import { compose } from 'redux'
import { connect } from 'react-redux';

import './App.css';

import withWidth,{isWidthUp} from '@material-ui/core/withWidth'

const drawerWidthPC = 255;

const styles = theme => ({
  root: {
    // display: "flex",
    // flexDirection: "row",
    // minHeight: "100vh"
    // fontFamily: "M PLUS Rounded 1c"
    color:"rgba(0,0,0,0.87)",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '40px',
    // paddingTop:"15px",
  },
  toolbar: {
    height: '40px',
    display: 'flex',
    alignItems:"flex-start",
    // display:"flex",
    // alignItems:"center",
    // margin:"auto",
    // justifyContent: "center",

    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '40px',
    },

  },
  typography: {
    fontFamily: 'Passero One',
    fontSize:'2em',
    flexGrow:"1",
    marginTop:"5px",
    height:"40px"
  },
  // drawer: {
  //   [theme.breakpoints.down('sm')]: {
  //     width:"100vw"
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     width:drawerWidthPC,
  //   },
  //   flexShrink: 0,
  // },
  drawerPaper: {
    [theme.breakpoints.down('sm')]: {
      width:"100vw"
    },
    [theme.breakpoints.up('sm')]: {
      width:drawerWidthPC
    },
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 1,
    // paddingBottom:"140px"
  },
  // toolbar: theme.mixins.toolbar,
//   -  circle: {
// -    display:'flex',
// -    justifyContent:'center',
// -    alignItems:'center',
// -    paddingTop:'200px'
// -  },
  // image: {
  //   display:'flex',
  //   alignItems:'center',
  // },
  map: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft:drawerWidthPC,
    },
    paddingTop:"40px"
  },
  // footer: {
  //   marginTop:"auto",
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  button: {
    // background:"#3f51b5",
    // color:"#ffffff",
    height: "40px",
    boxShadow:"none",
    marginLeft:"2px",
    // marginRight:"0px",
    // width: "1px"
    [theme.breakpoints.down('sm')]: {
      maxWidth:"36px",
      minWidth:"36px",
      maxHeight:"40px",
      minHeight:"40px"
    },
  },
});

class App extends Component {

  render() {
    const { classes, actions, width, form } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar} variant="dense">
            <Typography variant="h1" color="inherit" className={classes.typography}>
            EVENT MAP!
            </Typography>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => {actions.changeSettingsDialogOpen(true)}}><SettingsIcon /></Button>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => {actions.changeHowToUseDialogOpen(true)}}><HelpIcon /></Button>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => {window.open("https://goo.gl/forms/4L4LAoTdsVs0vbHp2",'_blank')}}><MailIcon /></Button>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>

          <Drawer
            //一定サイズのタブレット以上だと常にどろうあー表示する
            open={isWidthUp('sm',width)? true : form.drawerOpen}
            variant="persistent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <SearchForm />
          </Drawer>
            {/* <div className={classes.toolbar} /> */}
          <div className={classes.map}>
            <Map
              id="myMap"
              //ここでもオプション入れられるけど入れない
              onMapLoad={map => {
              }}
            />
          </div>
        </main>
        {/* ダイアログは後ろに出しとく */}
        <TermsOfServiceDialog />
        <HowToUseDialog />
        <SettingsDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  form:state.form
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(withStyles(styles), withWidth(), connect(mapStateToProps, mapDispatchToProps))(App);
