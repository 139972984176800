import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'

import { compose } from 'redux'
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from '../actions';

import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'
// import AutoCompTextField from './AutoComplete'

import Divider from '@material-ui/core/Divider'
import SearchIcon from '@material-ui/icons/Search'

import Calendar from 'rc-calendar'

import SelectDayOfTheWeek from './SelectDayOfTheWeek'
import "rc-calendar/assets/index.css"

import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ja'

import withWidth,{isWidthUp} from '@material-ui/core/withWidth'

//markerclusterer plusじゃないと対応してないlistenerがある
import * as MarkerClusterer from '@google/markerclustererplus'

const styles = theme => ({
  formWrapper: {
    //iphone5s等で縦スクロールを出すため
    [theme.breakpoints.down('sm')]: {
      overflow:"auto",
    },
    // overflow:"hidden",
  },
  formMain: {
    display:"flex",
    flexDirection: "column",
    alignItems:"center",
    // flexWrap: "wrap",
    // justifyContent:"center",
    // alignContent:"center"
  },
  button: {
    marginTop:"8px",
    width:"150px"
  },
  divider: {
    //やらないと高さ0でIE等で消える
    alignSelf: "stretch",
    marginTop:0,
    marginBottom:0
  },
  textfield: {
    // height: "52px",
    width: "220px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "6px",
      marginBottom:"1px"
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: "6px",
      marginBottom:"2px"
    },
  },
  formFooter: {
    // top:0,
    // left:0,
    // right:0,
    // bottom:0,
    // margin:"auto",
    [theme.breakpoints.down('sm')]: {
      lineHeight:"0.4em"
    },
  },
  copyright: {
    bottom: 0,
    textAlign:"center",
    [theme.breakpoints.down('sm')]: {
      width:"100%",
      position:"relative",
    },
    [theme.breakpoints.up('sm')]: {
      width:"254px",
      position:"absolute",
    },
  },
  socialButton: {
    textAlign:"center",
    // lineHeight:"11px"
    [theme.breakpoints.down('sm')]: {
      marginTop:"30px",
      marginBottom:0,
      paddingBottom:0,
      width:"100%",
      position:"relative",
      bottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width:"254px",
      position:"absolute",
      bottom: 40,
    },
  },
  // dayoftheweek: {
  //   [theme.breakpoints.down('sm')]: {
  //     height:"90px"
  //   },
  // }
})

//カレンダーの前の日付を非アクティブにする
function disabledDate(current) {
  if (!current) {
    return false;
  }
  const date = moment();
  date.hour(0);
  date.minute(0);
  date.second(0);
  return current.valueOf() < date.valueOf();
}

class SearchForm extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      selectedDate: today.getFullYear() + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + ("0"+today.getDate()).slice(-2),
    }

    //カレンダーの表示を日本語にする
    moment.locale('ja')

    const { actions } = this.props

    //キーワードが保存されてる
    if (localStorage.getItem("saveSearchWords")) {
        actions.changeKeywords(localStorage.getItem("saveSearchWords"))
    }
    //daygroupが保存されてる
    if (localStorage.getItem("saveDayGroup")) {
        actions.changeDayGroup(localStorage.getItem("saveDayGroup"))
        actions.changeDayPeriod(localStorage.getItem("saveDayPeriod"))
    }
  }

  render() {
    const {actions, form, events, classes, map, width} = this.props;

    const controlMarkersOnMap = (eventArray) => {
      let markers = [];
      for (let event of eventArray) {
        let marker = new window.google.maps.Marker({
          position: {lat: event.lat, lng: event.lon},
          event:event
          // map:map.map
        });
        const infoWindowContentString =
            <div className="gm-style-iw">
              <img src="./connpass_logo_4.png" alt="connpassイベント" className="iconImage"/>
              <a href={event.event_url} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>{event.title}</a><br />
              {event.catch ? <div style={{color:"rgba(0,0,0,0.85)"}}>{event.catch}</div> : ''}
              {moment(event.started_at).format('M月D日(ddd) HH:mm-')+moment(event.ended_at).format('HH:mm  ')}
              {event.limit ? "(" + (Number(event.accepted)+Number(event.waiting)) + "人/" + event.limit + "人)  ": "(" +event.accepted + "人)  "}
              {"管理:" + event.owner_display_name + "様 "}
            </div>
        let infowindow = new window.google.maps.InfoWindow({
          //高さ指定しないとめちゃ高くまで生成される
          content: renderToString(<div style={{maxHeight:"350px"}}>{infoWindowContentString}</div>),
        });

        marker.addListener("click", function() {
          //今のインフォウィンドウを閉じ、現在ウィンドウをセットし、オープンする
          actions.closeInfoWindow()
          actions.setInfoWindow(infowindow)
          infowindow.open(map.map, marker)
        });
        markers.push(marker);
      }
      //マーカークラスター作られてなければ作る
      if(map.markerCluster === null) {
        let initialGridSize = 40
        if(Number(localStorage.getItem('clusterGridSize'))) {
          initialGridSize = Number(localStorage.getItem('clusterGridSize'))
        }
        let markerCluster = new MarkerClusterer(map.map, markers,
          {
            //mから始まるm1 m2.png等を呼び出す
            imagePath: './m',
            //クリックでズームしない
            zoomOnClick: false,
            gridSize: initialGridSize,
            //中央をセンターにする
            averageCenter: true,
            //なんだっけ？
            enableRetinaIcons: true
          }
        )

        //クラスタがクリックされたときの動作、plusじゃないと反応しない
        window.google.maps.event.addListener(markerCluster, 'clusterclick', function(cluster) {
          actions.closeInfoWindow()

          const clusterInfoArray = []
          //マーカー全て取得
          const clickedMarkers = cluster.getMarkers()
          let currentEvent = ''
          for (let i = 0; i < clickedMarkers.length; i++) {
              currentEvent = clickedMarkers[i].event
              clusterInfoArray.push(
                <div key={i}>
                  <img src="./connpass_logo_4.png" alt="" className="iconImage"/>
                  <a href={currentEvent.event_url} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>{currentEvent.title}</a><br />
                  {currentEvent.catch ? <div style={{color:"rgba(0,0,0,0.85)"}}>{currentEvent.catch}<br /></div> : ''}
                  {moment(currentEvent.started_at).format('M月D日(ddd) HH:mm-')+moment(currentEvent.ended_at).format('HH:mm  ')}
                  {currentEvent.limit ? "(" + (Number(currentEvent.accepted)+Number(currentEvent.waiting)) + "人/" + currentEvent.limit + "人)  ": "(" +currentEvent.accepted + "人)  "}
                  {"管理:" + currentEvent.owner_display_name + "様 "}
                  {i === (clickedMarkers.length - 1) ? '': <Divider style={{margin:"3px 0"}}/>}
                </div>
              );
          }

          let infowindow = new window.google.maps.InfoWindow({
            // content: '<div id="infoWindow" style="max-height:250px;"/>',
            content: renderToString(<div style={{maxHeight:"350px"}}>{clusterInfoArray}</div>),
            position: cluster.getCenter(),
            //表示位置を縦横にずらせる
            pixelOffset: new window.google.maps.Size(1, -11)
          })

          actions.setInfoWindow(infowindow)
          infowindow.open(map.map)
        })
        //現在のマーカクラスタを保存しておく
        actions.setMarkerCluster(markerCluster);

      } else {
        //マーカークラスタに登録されたマーカーをクリアする
        actions.clearMarkerCluster()
        //アーカークラスタにマーカーを登録しなおす
        actions.addMarkersOnMarkerCluster(markers)
      }
    }

    const handleFetchData = () => {
      actions.requestData()
      axios.get('/api/events', {
          //クエリストリングの内容を指定
          params: {
            keywords:form.keywords,
            searchType:form.dayGroup,
            termType:form.dayPeriod,
            selectedDate:this.state.selectedDate
          }
      })
      .then(function(response) {
        const _eventArray = response.data
        actions.receiveDataSuccess(_eventArray)
        //新しくデータとったらインフォウィンドウも消しとく
        actions.closeInfoWindow()
        
        controlMarkersOnMap(_eventArray)
      })
      .catch(function(err) {
        console.error(new Error(err))
        actions.receiveDataFailed()
      })
    }

    const searchButtonClicked = () => {
      //スマホ以下の画面サイズの場合だけ非表示にする
      actions.changeDrawerOpen(false)
      handleFetchData()
    }

    const onCalendarChange = (newDate) => {
      //カレンダー変わったら入れ替える
      this.setState({selectedDate: newDate.format('YYYY-MM-DD')})
    }

    const onKeywordsChange = (e) => {
      actions.changeKeywords(e.target.value)
    }

    return (
      <div className={classes.formWrapper}>
        <div className={classes.formMain}>
          <Calendar 
            showDateInput={false} 
            showToday={false} 
            disabledDate={disabledDate}
            onChange={onCalendarChange}
            onSelect={() => actions.changeDayGroup('calendar')}
          />
          <div className={classes.dayoftheweek}>
            <SelectDayOfTheWeek />
          </div>

          <Divider variant="middle" className={classes.divider}/>

          <TextField
            id="outlined-with-placeholder"
            label="キーワード(OR指定)"
            placeholder="3ワードまで対応"
            margin="dense"
            variant="outlined"
            className={classes.textfield}
            onChange={onKeywordsChange}
            value={form.keywords}
          />

          {isWidthUp('sm',width) ? <Divider variant="middle" className={classes.divider} style={{marginTop:"4px"}}/>: ''}
          <Button className={classes.button} variant="contained" color="primary" disabled={events.isFetching} onClick={() => searchButtonClicked()}><SearchIcon /></Button>
          </div>
          <div className={classes.formFooter}>
            <div className={classes.socialButton}>
              <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-show-count="false" data-size="large" data-related="akahori_s">ツイート</a>
              <span style={{marginRight:"2px"}}></span>
              <div className="fb-share-button" data-href="https://event-map.info" data-layout="button" data-size="large" data-mobile-iframe="false"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fevent-map.info%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">シェア</a></div>
              <span style={{marginRight:"2px"}}></span>
              <a href="http://b.hatena.ne.jp/entry/" className="hatena-bookmark-button" data-hatena-bookmark-layout="basic" data-hatena-bookmark-width="20" data-hatena-bookmark-height="28" title="このエントリーをはてなブックマークに追加" ><img src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png" alt="このエントリーをはてなブックマークに追加" width="20" height="20" style={{border: "none"}}/></a>
            </div>
            <p className={classes.copyright}>© 2019-2020 <a href="https://twitter.com/akahori_s" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none",}}>@akahori_s</a> </p>
          </div>
        </div>
    )
  }
};

const mapStateToProps = (state) => ({
    events: state.events,
    form: state.form,
    map: state.map
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(withStyles(styles), withWidth(), connect(mapStateToProps, mapDispatchToProps))(SearchForm);