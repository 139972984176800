import { combineReducers } from 'redux'
import { 
  CHANGE_PREFECTURE, CHANGE_DATE, CHANGE_DAYGROUP, CHANGE_DAYPERIOD, CHANGE_KEYWORDS,
  INITIALIZE_FORM,
  REQUEST_DATA, RECEIVE_DATA_SUCCESS, RECEIVE_DATA_FAILED, 
  CHANGE_HOW_TO_USE_DIALOG_OPEN, CHANGE_TERMS_OF_SERVICE_DIALOG_OPEN, CHANGE_SETTINGS_DIALOG_OPEN,
  CHANGE_DRAWER_OPEN,
  SET_MAP, SET_MARKER_CLUSTER, CLEAR_MARKER_CLUSTER, ADD_MARKERS_ON_MARKER_CLUSTER,
  SET_INFO_WINDOW, CLOSE_INFO_WINDOW
} from './actions'

const initialState = {
  form: {
    drawerOpen: true,
    prefectures: '',
    date: '',
    dayGroup: 'calendar',
    dayPeriod: '',
    keywords: '',
  },
  events: {
    isFetching: false,
    eventArray: [],
    searched: false,
  },
  header: {
    howToUseDialogOpen: false,
    termsOfServiceDialogOpen: false,
    settingsDialogOpen: false,
  },
  map: {
    map: null,
    markerCluster: null,
    infoWindow: null,
  }
}

const formReducer = (state = initialState.form, action) => {
  switch (action.type) {
    case CHANGE_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.drawerOpen
      }
    case CHANGE_PREFECTURE:
      return {
        ...state,
        prefecture: action.prefecture,
      }
    case CHANGE_DATE:
      return {
        ...state,
        date: action.date,
      }
    case CHANGE_DAYGROUP:
      return {
        ...state,
        dayGroup: action.dayGroup,
    }
    case CHANGE_DAYPERIOD:
      return {
        ...state,
        dayPeriod: action.dayPeriod,
    }
    case CHANGE_KEYWORDS:
      return {
        ...state,
        keywords: action.keywords
      }
    case INITIALIZE_FORM:
      return initialState.form
    default:
      return state
  }
}

const eventsReducer = (state = initialState.events, action) => {
    switch (action.type) {
      case REQUEST_DATA:
       return {
         ...state,
         isFetching: true,
         isMarkerShown: false,
       }
     case RECEIVE_DATA_SUCCESS:
       return {
         ...state,
         isFetching: false,
        //  eventArray: action.eventArray,
         eventArray: adjustCordinates(action.eventArray),
         searched: true,
         isMarkerShown: true,
       }
     case RECEIVE_DATA_FAILED:
       return {
         ...state,
         isFetching: false,
         isMarkerShown: false
       }
      default:
      return state
    }
}

const headerReducer = (state = initialState.header, action) => {
    switch (action.type) {
      case CHANGE_HOW_TO_USE_DIALOG_OPEN:
       return {
         ...state,
         howToUseDialogOpen: action.howToUseDialogOpen
       }
      case CHANGE_TERMS_OF_SERVICE_DIALOG_OPEN:
       return {
         ...state,
         termsOfServiceDialogOpen: action.termsOfServiceDialogOpen
       }
      case CHANGE_SETTINGS_DIALOG_OPEN:
        return{
          ...state,
          settingsDialogOpen: action.settingsDialogOpen
        }
      default:
      return state
    }
}

const mapReducer = (state = initialState.map, action) => {
    switch (action.type) {
      case SET_MAP:
       return {
         ...state,
         map: action.map
       }
      case SET_MARKER_CLUSTER:
        return {
         ...state,
         markerCluster: action.markerCluster
        }
      case CLEAR_MARKER_CLUSTER:
        state.markerCluster.clearMarkers()
        return {
         ...state,
        }
      case ADD_MARKERS_ON_MARKER_CLUSTER:
        state.markerCluster.addMarkers(action.markers)
        return {
          ...state,
        }
      case SET_INFO_WINDOW:
        return {
          ...state,
          infoWindow: action.infoWindow
        }
      case CLOSE_INFO_WINDOW:
        state.infoWindow && state.infoWindow.close()
        return {
          ...state,
        }
      default:
      return state
    }
}

function adjustCordinates (eventArray) {

//   // console.log(eventArray)

//   //lat,lon,の昇順にそーと
  return eventArray.sort(function(a,b) {
    // if(a.lat < b.lat) return -1;
    // if(a.lat > b.lat) return 1;
    // if(a.lon < b.lon) return -1;
    // if(a.lon > b.lon) return 1;

    //上が-1が正解
    if(a.started_at < b.started_at) return -1;
    if(a.started_at > b.started_at) return 1;
    return 0;
  });

//   //元データの始点を繰り返す length = 2のときは0までで良い
//   //[1, 2, 2, 2, 3, 4]のときは？length=6
  
//   for (let i = 0; i < (eventArray.length - 1); i++) {
//     //始点から何個同じ値が入っているか？
//     for(let j = 1; (i + j) < eventArray.length; j++) {
//       //違うデータになった時
//       // console.log("i =" + i + " j = " + j)
//       // console.log(eventArray[i].lat)
//       // console.log(eventArray[i+j].lat)

//       // eventArray[i].lat !== 0 &&
//       if (!(eventArray[i].lat === eventArray[i+j].lat &&
//           eventArray[i].lon === eventArray[i+j].lon)) {
//             //二回以上繰り返している場合
//           if (j >= 2) {
//             for (let k = i; k <= ((i + j) - 1); k++) {
//               eventArray[k].samePlaceInfo = {"start":i,"number":j}
//               // console.log("start =" + i + " number = " + j)
//               // for (let m = 0; m < j; m++) {
//               //   console.log(eventArray[i+m].title);
//               //   console.log(eventArray[i+m].lat)
//               //   console.log(eventArray[i+m].lon)
//               // }
//             }
//             i += (j - 1);
//             // // console.log("i =" + i + " j = " + j)
//             // // for (let m = 0; m < j; m++) {
//             // //   console.log(eventArray[i+m].title);
//             // //   console.log(eventArray[i+m].lat)
//             // //   console.log(eventArray[i+m].lon)
//             // // }
//             // let lon_radius = 0.0002;
//             // let lat_to_lng= 1.5
//             // let angle = 0.5
//             // //一個は正確な位置に置く
//             // let loclen = j - 1
//             // let step = 2 * Math.PI / loclen
//             // let lat_radius = lon_radius / lat_to_lng;
//             // //始点の一個後から、最終点(i + j)-1
//             // //2データの場合、1から1まで
//             // for (let k = (i + 1); k <= ((i + j) - 1); k++) {
//             //   eventArray[k].lon += (Math.cos(angle) * lon_radius);
//             //   eventArray[k].lat += (Math.sin(angle) * lat_radius);
//             //   angle += step;
//             // }
//           }
//           break;
//       }

//     }
//   }

//   // event.lat, event.lon
  // return eventArray
}

const rootReducer = combineReducers({
  form: formReducer,
  events: eventsReducer,
  header: headerReducer,
  map: mapReducer
})

export default rootReducer