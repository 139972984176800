import React, { Component } from 'react';
import { connect } from 'react-redux';

import { compose } from 'redux'
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

import * as actions from '../actions';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControlLeft: {
    marginLeft: theme.spacing.unit*2 ,
  },
  formControlRight: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '33px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '41px',
    },
  },
  group: {
    // margin: `${theme.spacing.unit}px 0`,
  },
  radio: {
    [theme.breakpoints.down('sm')]: {
      margin:"4px 10px 4px 12px",
    },
    [theme.breakpoints.up('sm')]: {
      margin:"10px 10px 6px 12px",
    },
    padding:"0px"
  },
  radioLabel: {
    color:"rgba(0,0,0,0.83)"
  },
  radioBottom: {
    [theme.breakpoints.down('sm')]: {
      margin:"4px 10px 6px 12px",
    },
    [theme.breakpoints.up('sm')]: {
      margin:"10px 10px 13px 12px",
    },
    padding:"0px"
  },
});

class SelectDayOfTheWeek extends Component {

  // state = {
  //   calendarSelected: true,
  // };


  render() {
    const { form } = this.props;
    const { classes } = this.props;
    const {actions} = this.props;

    const handleChangeLeft = event => {
      actions.changeDayGroup( event.target.value );
      if (event.target.value === 'calendar') {
        // actions.changeDayPeriod('');
        // this.setState({calendarSelected: true})
      } else {
        if (form.dayPeriod === '') {
          actions.changeDayPeriod('withinaweek')
        }
        // this.setState({calendarSelected: false})
      }
    };
      const handleChangeRight = event => {
      if (form.dayGroup !== 'calendar') {
        actions.changeDayPeriod( event.target.value );
      }
    };

    // const GoogleMapComponent = withGoogleMap((props) => (
    return(
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControlLeft}>
          {/* <FormLabel component="legend">Gender</FormLabel> */}
          <RadioGroup
            aria-label="DayGroup"
            name="DayGroup"
            className={classes.group}
            value={form.dayGroup}
            onChange={handleChangeLeft}
          >
            <FormControlLabel value="calendar" control={<Radio color="primary" className={classes.radio}/>} label={<span className={classes.radioLabel}>日付指定</span>} />
            <FormControlLabel value="weekend" control={<Radio color="primary" className={classes.radio}/>} label={<span className={classes.radioLabel}>土日</span>} />
            <FormControlLabel value="weekday" control={<Radio color="primary" className={classes.radioBottom}/>} label={<span className={classes.radioLabel}>月〜金</span>} />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControlRight}>
          {/* <FormLabel component="legend">Gender</FormLabel> */}
          <RadioGroup
            aria-label="DayPeriod"
            name="DayPeriod"
            className={classes.group}
            value={form.dayPeriod}
            onChange={handleChangeRight}
          >
            <FormControlLabel
              value="withinaweek"
              control={<Radio color="secondary" className={classes.radio}/>}
              label={<span className={classes.radioLabel}>1週間以内</span>}
              disabled={form.dayGroup === 'calendar'}
            />
            <FormControlLabel
              value="within3weeks"
              control={<Radio color="secondary" className={classes.radioBottom}/>}
              label={<span className={classes.radioLabel}>3週間以内</span>}
              // disabled={this.state.calendarSelected}
              disabled={form.dayGroup === 'calendar'}
            />
          </RadioGroup>
          {/* <FormHelperText>labelPlacement start</FormHelperText> */}
        </FormControl>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  form: state.form,
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SelectDayOfTheWeek);