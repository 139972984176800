
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button'

import { bindActionCreators } from 'redux';

import moment from 'moment';

import {Circle} from 'better-react-spinkit';
import ReplyIcon from '@material-ui/icons/Reply'
import { compose } from 'redux'
import withWidth,{isWidthUp} from '@material-ui/core/withWidth'
import * as actions from '../actions';

class Map extends Component {

  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this)

    moment.updateLocale("ja",
      { weekdaysShort: ["日","月","火","水","木","金","土",] }
    );
  }

  onScriptLoad() {
    const {actions} = this.props

    const centerData = {
      'tokyo': { lat: 35.685175, lng: 139.7528 },
      'osaka': { lat: 34.698695, lng: 135.491583},
      'aichi': { lat: 35.170915, lng: 136.881537},
      'hukuoka': { lat: 33.588905, lng: 130.421447},
      'null': { lat:0, lng:0},
    }
    let initialCenter = ''

    //localstorageにデータがあり配列にマッチしたら
    if (localStorage.getItem('prefecture') && centerData[localStorage.getItem('prefecture')]) {
      initialCenter = centerData[localStorage.getItem('prefecture')]
    } else {
      //デフォルトセンター
      initialCenter = { lat: 35.685175, lng: 139.7528 }
    }

    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      //オプション
      {
        center: initialCenter,
        zoom: 12,
        gestureHandling:'greedy',
        draggable:true,
        //航空ボタンを表示するか
        mapTypeControl:false,
        //ストリートビューボタンを表示するか
        streetViewControl: false,
        //POI(観光地とかのクリックできるやつ)を設置するか
        styles: [
          {
            featureType:'poi',
            stylers: [{visibility: 'off'}]
          },
        ]
      }
    );
    // this.props.onMapLoad(map)

    window.google.maps.event.addListener(map, 'zoom_changed', function() {
      actions.closeInfoWindow()
    })
    map.addListener('drag', function() {
      actions.closeInfoWindow()
    });
    map.addListener('click', function() {
      actions.closeInfoWindow()
    });

    // マップをセットしておく
    actions.setMap(map)
  }

  componentDidMount() {
    //google mapsが読み込まれているか？
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyDopI7eCKuEB1cOQKfflfz2gjVOpksUI6Q`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important. 
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', e => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }

  render() {
    const {width, actions, events} = this.props

    return (
      <div style={{position: "relative"}}>
       {isWidthUp('sm',width) ?
                <React.Fragment>
                <div style={{ width: "100%", height: "calc(100vh - 40px)",position:"absolute"}} id={this.props.id} />
                  {events.isFetching &&
                    <div style={{textAlign:"center",height:"calc(100vh - 40px)",lineHeight:"calc(100vh - 40px)"}}><span style={{display:"inline-block",verticalAlign:"middle"}}><Circle size={200} color={"#3f51b5"} style={{margin:"0 auto"}} /></span></div>
                  }
                </React.Fragment>
              :
              <React.Fragment>
                <div style={{ width: "100%", height:(window.innerHeight - 40 - 50) + "px",lineHeight:(window.innerHeight - 40 - 50) + "px" ,position:"absolute"}} id={this.props.id} />
                {events.isFetching &&
                  <div style={{textAlign:"center",height:(window.innerHeight - 40 - 50) + "px",lineHeight:(window.innerHeight - 40 - 50) + "px"}}><span style={{display:"inline-block",verticalAlign:"middle"}}><Circle size={200} color={"#3f51b5"} style={{margin:"0 auto"}} /></span></div>
                }
                <Button style={{height:"50px",width:"100vw", top:(window.innerHeight - 40 - 50) + "px", position:"absolute"}} onClick={() => actions.changeDrawerOpen(true)} variant="contained" color="primary" ><ReplyIcon /></Button>
              </React.Fragment>
       }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.events,
  map: state.map
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(withWidth(), connect(mapStateToProps, mapDispatchToProps))(Map);