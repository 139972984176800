import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';

import withWidth,{isWidthUp} from '@material-ui/core/withWidth'
import { compose } from 'redux'

class TermsOfServiceDialog extends React.Component {

  render() {

    const {actions, header, width} = this.props
    let isFullScreen = true
    if (isWidthUp('sm',width)) {
      isFullScreen = false
    }

    return (
      <React.Fragment>
        <Dialog
          open={header.termsOfServiceDialogOpen}
          // onClose={this.handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          fullScreen={isFullScreen}
          //画面外クリックしたときに発動
          onClose={() => {actions.changeTermsOfServiceDialogOpen(false)}}
        >
          <DialogTitle id="scroll-dialog-title">利用規約・プライバシーポリシー</DialogTitle>
          <DialogContent>
            <DialogContentText>
            利用規約<br/><br/>
              第1条（適用）
本規約は、ユーザーと当方との間の本サービスの利用に関わる一切の関係に適用されるものとします。<br/>
当方は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。<br/>
これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。<br/>
本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。<br/>
<br/>
第2条（禁止事項）
ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。<br/>
法令または公序良俗に違反する行為<br/>
犯罪行為に関連する行為<br/>
当方、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為<br/>
当方のサービスの運営を妨害するおそれのある行為<br/>
不正アクセスをし、またはこれを試みる行為<br/>
他のユーザーに関する個人情報等を収集または蓄積する行為<br/>
不正な目的を持って本サービスを利用する行為<br/>
本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為<br/>
他のユーザーに成りすます行為<br/>
当方が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為<br/>
面識のない異性との出会いを目的とした行為<br/>
当方のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為<br/>
その他、当方が不適切と判断する行為<br/>
<br/>
第3条（本サービスの提供の停止等）
当方は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br/>
本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br/>
地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合<br/>
コンピュータまたは通信回線等が事故により停止した場合<br/>
その他、当方が本サービスの提供が困難と判断した場合<br/>
当方は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。<br/>
<br/>
第4条（利用制限および登録抹消）
当方は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限することができるものとします。<br/>
本規約のいずれかの条項に違反した場合<br/>
当方からの連絡に対し、一定期間返答がない場合<br/>
その他、当方が本サービスの利用を適当でないと判断した場合<br/>
当方は、本条に基づき当方が行った行為によりユーザーに生じた損害について、一切の責任を負いません。<br/>
<br/>
第5条（保証の否認および免責事項）
当方は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<br/>
当方は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当方とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。<br/>
前項ただし書に定める場合であっても、当方は、当方の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当方またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。<br/>
当方は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。<br/>
<br/>
第6条（サービス内容の変更等）
当方は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。<br/>
<br/>
第7条（利用規約の変更）
当方は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。<br/>
<br/>
第8条（権利義務の譲渡の禁止）
ユーザーは、当方の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。<br/>
<br/>
第9条（準拠法・裁判管轄）
本規約の解釈にあたっては、日本法を準拠法とします。<br/>
本サービスに関して紛争が生じた場合には、当方の本店所在地を管轄する裁判所を専属的合意管轄とします。<br/>
<br/>
2019年3月2日制定
<br/><br/><br/>
プライバシーポリシー<br/><br/>

第1条<br/>
当サイトでは、お問い合わせなどの際に、名前、メールアドレス等の個人情報をご登録いただく場合がございます。<br/>
これらの個人情報は質問に対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただくものであり、それ以外の目的では利用いたしません。<br/>
<br/>
第2条（個人情報の第三者への開示）<br/>
当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。<br/>
・本人のご了解がある場合<br/>
・法令等への協力のため、開示が必要となる場合<br/>
<br/>
第3条（個人情報の開示、訂正、追加、削除、利用停止）<br/>
ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。<br/>
<br/>
第4条（アクセス解析ツールについて）<br/>
当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br/>
このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。<br/>
このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br/>
<br/>
第5条（プライバシーポリシーの変更について）<br/>
当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。<br/>
修正された最新のプライバシーポリシーは常に本ページにて開示されます。<br/>
<br/>
2019年3月2日制定
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {actions.changeTermsOfServiceDialogOpen(false)}} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  header: state.header,
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

// export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(App);
export default compose(withWidth(), connect(mapStateToProps, mapDispatchToProps))(TermsOfServiceDialog);