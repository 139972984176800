import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Select from '@material-ui/core/Select'
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider'
// import Checkbox from '@material-ui/core/Checkbox'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles';

import withWidth,{isWidthUp} from '@material-ui/core/withWidth'

import TextField from '@material-ui/core/TextField'

import * as actions from '../actions';

// import SelectDayOfTheWeek from './SelectDayOfTheWeek'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = theme => ({
  // root: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  // },
  formControl: {
    margin: theme.spacing.unit,
    // minWidth: 120,
  },
  // selectEmpty: {
  //   marginTop: theme.spacing.unit * 2,
  // },
  button: {
    // marginTop:"8px",
    maxWidth:"78px",
    minWidth:"78px",
    [theme.breakpoints.down('sm')]: {
      // maxWidth:"80px",
      // minWidth:"80px",
      // fontSize:'14px',
    },
  },
  divider: {
    marginTop:"4px",
    marginBottom:"4px",
  },
  dialogWrapper: {
    // width:300,
    // display:"flex",
    // flexDirection: "column",
    // flexWrap: "wrap",
    // justifyContent:"center",
    // alignItems:"center",
    // alignContent:"center"
    color:"rgba(0,0,0,0.80)",

    //タブレット以下ぐらいなら全画面になるからセンター寄せする
    [theme.breakpoints.down('sm')]: {
      textAlign:"center"
    },
  },
  //以下曜日選択用
  formControlLeft: {
    marginLeft: theme.spacing.unit*2 ,
  },
  formControlRight: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '33px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '41px',
    },
  },
  group: {
    // margin: `${theme.spacing.unit}px 0`,
  },
  radio: {
    [theme.breakpoints.down('sm')]: {
      margin:"4px 10px 4px 12px",
    },
    [theme.breakpoints.up('sm')]: {
      margin:"10px 10px 6px 12px",
    },
    padding:"0px"
  },
  radioLabel: {
    color:"rgba(0,0,0,0.80)"
  },
  radioBottom: {
    [theme.breakpoints.down('sm')]: {
      margin:"4px 10px 6px 12px",
    },
    [theme.breakpoints.up('sm')]: {
      margin:"10px 10px 13px 12px",
    },
    padding:"0px"
  },
  textfield: {
    width:"250px"
  }
});

class SettingsDialog extends React.Component {

  state = {
    prefecture: '',
    clusterGridSize: '',
    // saveDayGroupFlg: false,
    // saveSearchWordsFlg: false,
    // labelWidth: 0,
    saveDayGroup: '',
    saveDayPeriod: '',
    // localStorage.removeItem('saveSearchWordsFlg')
    saveSearchWords: ''
  };
  componentDidMount() {
    if(localStorage.getItem('prefecture')) {
      this.setState({prefecture : localStorage.getItem('prefecture')})
    } else {
      this.setState({prefecture : 'tokyo'})
    }
    if(localStorage.getItem('clusterGridSize')) {
      this.setState({clusterGridSize : localStorage.getItem('clusterGridSize')})
    } else {
      this.setState({clusterGridSize : '35'})
    }
    if(localStorage.getItem('saveDayGroup')) {
      this.setState({saveDayGroup : localStorage.getItem('saveDayGroup')})

      //dayGroupが設定されてなければPeriodもない
      if(localStorage.getItem('saveDayPeriod')) {
        this.setState({saveDayPeriod : localStorage.getItem('saveDayPeriod')})
      } else {
        this.setState({saveDayPeriod : ''})
      }

    } else {
      this.setState({saveDayGroup : 'calendar'})
    }

    if(localStorage.getItem('saveSearchWords')) {
      this.setState({saveSearchWords : localStorage.getItem('saveSearchWords')})
    } else {
      this.setState({saveSearchWords : ''})
    }
    // if(localStorage.getItem('saveDayGroupFlg')) {
    //   this.setState({saveDayGroupFlg : localStorage.getItem('saveDayGroupFlg') === 'true' ? true : false})
    // } else {
    //   this.setState({saveDayGroupFlg : false})
    // }
    // if(localStorage.getItem('saveSearchWordsFlg')) {
    //   this.setState({saveSearchWordsFlg : localStorage.getItem('saveSearchWordsFlg') === 'true' ? true : false})
    // } else {
    //   this.setState({saveSearchWordsFlg : false})
    // }

  }

  handleChangeLeft = event => {
    this.setState({saveDayGroup:event.target.value})
    if (event.target.value === 'calendar') {
      // this.setState({saveDayPeriod:''})
    } else {
      if (this.state.saveDayPeriod === '') {
        this.setState({saveDayPeriod:'withinaweek'})
      }
    }
  };
  handleChangeRight = event => {
    this.setState({saveDayPeriod: event.target.value });
  };

  selectChanged = event => {
    // console.log(event.target)
    if (event.target.checked === true || event.target.checked === false) {
      this.setState({ [event.target.name] : event.target.checked });
    } else {
      this.setState({ [event.target.name] : event.target.value });
    }
  };

  onKeywordsChanged = event => {
    this.setState({'saveSearchWords':event.target.value})
  }

  closeButtonClicked = event => {
    const {map, actions} = this.props
    localStorage.setItem('prefecture', this.state.prefecture)
    localStorage.setItem('clusterGridSize', this.state.clusterGridSize)
    // localStorage.setItem('saveDayGroupFlg', this.state.saveDayGroupFlg)
    localStorage.setItem('saveDayGroup', this.state.saveDayGroup)
    localStorage.setItem('saveDayPeriod', this.state.saveDayPeriod)
    // localStorage.setItem('saveSearchWordsFlg', this.state.saveSearchWordsFlg)
    localStorage.setItem('saveSearchWords', this.state.saveSearchWords)

    //フォームのキーワード更新
    actions.changeKeywords(this.state.saveSearchWords)

    //フォームの曜日選択更新
    actions.changeDayGroup(this.state.saveDayGroup)
    actions.changeDayPeriod(this.state.saveDayPeriod)

    //まとまるサイズ変更
    map.markerCluster && map.markerCluster.setGridSize(Number(localStorage.getItem('clusterGridSize')))
    map.markerCluster && map.markerCluster.repaint()

    //マップの表示位置変更
    const centerData = {
      'tokyo': { lat: 35.685175, lng: 139.7528 },
      'osaka': { lat: 34.698695, lng: 135.491583},
      'aichi': { lat: 35.170915, lng: 136.881537},
      'hukuoka': { lat: 33.588905, lng: 130.421447},
      'null': { lat:0, lng:0},
    }
    map.map.setCenter(centerData[localStorage.getItem('prefecture')])

    //ダイアログを閉じる
    actions.changeSettingsDialogOpen(false)
  }
  offScreenClicked = event => {
    const {actions} = this.props
    actions.changeSettingsDialogOpen(false)
  }


  clearSettings = () => {
    this.setState({prefecture : 'tokyo'})
    this.setState({clusterGridSize : '35'})
    this.setState({saveDayGroup: 'calendar'})
    this.setState({saveDayPeriod: ''})
    this.setState({saveSearchWords: ''})
    // this.setState({saveDayGroupFlg : false})
    // this.setState({saveSearchWordsFlg : false})
    localStorage.removeItem('prefecture')
    localStorage.removeItem('clusterGridSize')

    // localStorage.removeItem('saveDayGroupFlg')
    localStorage.removeItem('saveDayGroup')
    localStorage.removeItem('saveDayPeriod')

    // localStorage.removeItem('saveSearchWordsFlg')
    localStorage.removeItem('saveSearchWords')
  }

  render() {

    const {header, classes, width} = this.props
    let isFullScreen = true
    if (isWidthUp('sm',width)) {
      isFullScreen = false
    }

    return (
      <React.Fragment>
        <Dialog 
          open={header.settingsDialogOpen}
          // onClose={this.handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          // fullWidth={true}
          fullScreen={isFullScreen}
          // maxWidth={'xs'}
          // className={classes.dialog}

          //画面外クリックしたときに発動
          onClose={this.offScreenClicked}
        >
          <div className={classes.dialogWrapper}>
          <DialogTitle id="scroll-dialog-title">規定値の設定</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            </DialogContentText> */}
              {/* <Divider /> */}
              初期表示場所<br />
              <FormControl className={classes.formControl}>
                {/* <InputLabel htmlFor="prefecture">初期表示場所</InputLabel> */}
                <Select
                  value={this.state.prefecture}
                  onChange={this.selectChanged}
                  input={<Input name="prefecture" id="prefecture"/>}
                >
                  {/* <MenuItem value="">
                    <em>未選択</em>
                  </MenuItem> */}
                  <MenuItem value="tokyo"><span className={classes.radioLabel}>東京都</span></MenuItem>
                  <MenuItem value="osaka"><span className={classes.radioLabel}>大阪府</span></MenuItem>
                  <MenuItem value="aichi"><span className={classes.radioLabel}>愛知県</span></MenuItem>
                  <MenuItem value="hukuoka"><span className={classes.radioLabel}>福岡県</span></MenuItem>
                  <MenuItem value="null"><span className={classes.radioLabel}>オンライン/座標未登録</span></MenuItem>
                </Select>
                {/* <FormHelperText>Read only</FormHelperText> */}
              </FormControl><br/>
              {/* <divider></divider> */}
              <Divider className={classes.divider}/>
              イベントの集合範囲<br />
              <FormControl className={classes.formControl}>
                {/* <InputLabel htmlFor="prefecture">設定値</InputLabel> */}
                <Select
                  value={this.state.clusterGridSize}
                  onChange={this.selectChanged}
                  input={<Input name="clusterGridSize" id="clusterGridSize"/>}
                >
                  {/* <MenuItem value="25">かなりまとまらない</MenuItem> */}
                  <MenuItem value="30"><span className={classes.radioLabel}>30px</span></MenuItem>
                  <MenuItem value="35"><span className={classes.radioLabel}>推奨(35px)</span></MenuItem>
                  <MenuItem value="60"><span className={classes.radioLabel}>60px</span></MenuItem>
                  <MenuItem value="90"><span className={classes.radioLabel}>90px</span></MenuItem>
                  <MenuItem value="150"><span className={classes.radioLabel}>150px</span></MenuItem>
                  <MenuItem value="300"><span className={classes.radioLabel}>300px</span></MenuItem>
                </Select>
              </FormControl><br/>
              <Divider className={classes.divider}/>
              検索曜日・期間<br/>
              <FormControl component="fieldset" className={classes.formControlLeft}>
                {/* <FormLabel component="legend">Gender</FormLabel> */}
                <RadioGroup
                  aria-label="DayGroup"
                  name="DayGroup"
                  // className={classes.group}
                  value={this.state.saveDayGroup}
                  onChange={this.handleChangeLeft}
                >
                  <FormControlLabel value="calendar" control={<Radio color="primary" className={classes.radio}/>} label={<span className={classes.radioLabel}>日付指定</span>} />
                  <FormControlLabel value="weekend" control={<Radio color="primary" className={classes.radio}/>} label={<span className={classes.radioLabel}>土日</span>} />
                  <FormControlLabel value="weekday" control={<Radio color="primary" className={classes.radioBottom}/>} label={<span className={classes.radioLabel}>月〜金</span>} />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" className={classes.formControlRight}>
                {/* <FormLabel component="legend">Gender</FormLabel> */}
                <RadioGroup
                  aria-label="DayPeriod"
                  name="DayPeriod"
                  // className={classes.group}
                  value={this.state.saveDayPeriod}
                  onChange={this.handleChangeRight}
                >
                  <FormControlLabel
                    value="withinaweek"
                    control={<Radio color="secondary" className={classes.radio}/>}
                    label={<span className={classes.radioLabel}>1週間以内</span>}
                    disabled={this.state.saveDayGroup === 'calendar'}
                  />
                  <FormControlLabel
                    value="within3weeks"
                    control={<Radio color="secondary" className={classes.radioBottom}/>}
                    label={<span className={classes.radioLabel}>3週間以内</span>}
                    // disabled={this.state.calendarSelected}
                    disabled={this.state.saveDayGroup === 'calendar'}
                  />
                </RadioGroup>
                {/* <FormHelperText>labelPlacement start</FormHelperText> */}
              </FormControl>
              <br/>

              {/* <Checkbox
                name='saveDayGroupFlg'
                checked={this.state.saveDayGroupFlg}
                onChange={this.selectChanged}
                color="primary"
              /><br/> */}
              <Divider className={classes.divider}/>
              検索キーワード<br/>
              <TextField
                id="outlined-with-placeholder"
                label="キーワード(OR指定)"
                placeholder="3ワードまで対応"
                margin="dense"
                variant="outlined"
                className={classes.textfield}
                onChange={this.onKeywordsChanged}
                value={this.state.saveSearchWords}
              />
              {/* <Checkbox
                name='saveSearchWordsFlg'
                checked={this.state.saveSearchWordsFlg}
                onChange={this.selectChanged}
                color="primary"
              /><br /> */}
          </DialogContent>
          <DialogActions>
            {/* <Button className={classes.button} variant="contained" color="primary" onClick={this.saveButtonClicked}>
              SAVE
            </Button> */}
            {/* <Button onClick={this.clearSettings} className={classes.button} variant="contained" color="primary"> */}
            <Button onClick={this.clearSettings} color="primary">
              初期化
            </Button>
            {/* <Button onClick={this.closeButtonClicked} className={classes.button} variant="contained" color="primary"> */}
            <Button onClick={this.closeButtonClicked} color="primary">
              保存して閉じる
            </Button>
          </DialogActions>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  header: state.header,
  form: state.form,
  map: state.map
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(withStyles(styles), withWidth(), connect(mapStateToProps, mapDispatchToProps))(SettingsDialog);